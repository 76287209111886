/* transform */
/* Filters */
/* background-gradient V1 */
/* background-gradient V2 */
/* background-gradient V3 */
/* Use :
.selector {
    @include x-multiple-colored-gradient(
                    'top',
                    #f22 0%,
                    #f2f 15%,
                    #22f 30%,
                    #2ff 45%,
                    #2f2 60%,
                    #2f2 75%,
                    #ff2 90%,
                    #f22 100%
    );
}
*/
/* Animation */
/*
    Use :
    @include keyframe(fadeout) {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
    @include animation(10s, 5s, fadeout)
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

#wrapper {
  background: #ffffff; }

.container {
  width: 1280px;
  max-width: 100%;
  margin: auto; }
  .container .container {
    padding: 0; }

.header--row-1 {
  background-color: #a3d2eb;
  padding: 2px 0;
  border-bottom: 2px solid #ffffff; }
  .header--row-1--inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1023px) {
      .header--row-1--inner {
        flex-direction: column; } }

.header--logo {
  position: relative;
  padding: 0 10px;
  margin: 5px 0;
  text-align: center; }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .header--logo img {
      max-width: 220px;
      height: auto; } }

.header--search-contact-user-cart {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }
  @media (max-width: 1023px) {
    .header--search-contact-user-cart {
      display: none; }
      .menu-account-open .header--search-contact-user-cart {
        display: flex;
        justify-content: center;
        flex-wrap: wrap; } }

.header--search-contact, .header--user-cart {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  @media (max-width: 580px) {
    .header--search-contact, .header--user-cart {
      flex-wrap: wrap; } }

.header--search-contact .search-form,
.header--search-contact .customer-service {
  margin: 5px 0; }

.header--search-contact .search-form {
  position: relative;
  padding: 0 10px;
  width: 335px;
  max-width: 100%; }
  @media (max-width: 359px) {
    .header--search-contact .search-form {
      width: 100%; } }
  .header--search-contact .search-form input[type="text"] {
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #20233f;
    height: 40px;
    padding: 5px 60px 5px 20px;
    margin: 6px 0;
    border: 1px solid #ffffff;
    border-radius: 20px;
    width: 100%;
    max-width: 100%; }
  .header--search-contact .search-form button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0 0 0 -53px;
    background: none;
    border: none; }

.header--search-contact .customer-service {
  position: relative;
  padding: 0 10px;
  white-space: nowrap; }
  .header--search-contact .customer-service .txt {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }
  .header--search-contact .customer-service .txt-1 {
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    text-transform: uppercase; }
  .header--search-contact .customer-service .txt-2 {
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #20233f; }

.header--user-cart .account,
.header--user-cart .cart {
  position: relative;
  text-align: center;
  white-space: normal;
  margin: 5px 0;
  padding: 0 10px; }
  .header--user-cart .account .title,
  .header--user-cart .cart .title {
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    color: #20233f;
    text-transform: uppercase; }

.header--user-cart .account--logged {
  padding-right: 15px; }

.header--user-cart .account--logout {
  position: absolute;
  top: 0;
  right: 0; }
  .header--user-cart .account--logout .picto {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    border-radius: 100%;
    background-color: #20233f;
    text-align: center;
    color: #ffffff;
    vertical-align: middle; }

.header--user-cart .cart .cart-number {
  position: absolute;
  right: -10px;
  bottom: 0;
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 100%;
  font-family: "Lato", Tahoma, Arial, sans-serif;
  font-weight: 900;
  font-size: 12px;
  line-height: 21px;
  color: #20233f; }

.header--row-2 {
  background-color: #20233f; }
  .header--row-2 .container {
    display: flex;
    padding: 0;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (min-width: 1024px) {
      .header--row-2 .container {
        flex-wrap: nowrap; } }
  .header--row-2 .break {
    flex-basis: 100%;
    height: 0; }
    @media (min-width: 1024px) {
      .header--row-2 .break {
        display: none; } }

.picto {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 53px;
  line-height: 53px;
  font-size: 22px;
  border-radius: 100%;
  background-color: #20233f;
  text-align: center;
  color: #ffffff;
  vertical-align: middle; }

#menu-search {
  display: none; }
  @media (max-width: 1023px) {
    #menu-search {
      display: block;
      flex: 1; } }
  #menu-search .search-form {
    padding: 0;
    width: auto !important; }
    #menu-search .search-form label, #menu-search .search-form input {
      font-size: 12px; }
    #menu-search .search-form .picto {
      font-size: 16px;
      border-radius: 0;
      width: 32px; }
  #menu-search .customer-service {
    display: none; }

#menu-trigger {
  display: none; }
  @media (max-width: 1023px) {
    #menu-trigger {
      display: block; } }
  #menu-trigger button {
    padding: 0;
    margin: 0;
    border: none;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    background: none;
    color: #ffffff;
    -moz-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s; }
    #menu-trigger button .fa-times {
      display: none; }
    #menu-trigger button .fa-bars {
      display: inline-block; }
    #menu-trigger button:hover {
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    .menu-open #menu-trigger button .fa-times {
      display: inline-block; }
    .menu-open #menu-trigger button .fa-bars {
      display: none; }

#menu-account-trigger {
  display: none; }
  @media (max-width: 1023px) {
    #menu-account-trigger {
      display: block;
      float: right; } }
  #menu-account-trigger button {
    padding: 0;
    margin: 0;
    border: none;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    background: none;
    color: #ffffff;
    -moz-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s; }
    #menu-account-trigger button .fa-user-times {
      display: none; }
    #menu-account-trigger button .fa-user-plus {
      display: inline-block; }
    #menu-account-trigger button:hover {
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    .menu-account-open #menu-account-trigger button .fa-user-times {
      display: inline-block; }
    .menu-account-open #menu-account-trigger button .fa-user-plus {
      display: none; }

#primary-menu {
  border-bottom: 1px solid #20233f;
  flex: 1; }
  @media (max-width: 1023px) {
    #primary-menu {
      display: none; }
      .menu-open #primary-menu {
        display: block; }
        .menu-open #primary-menu > ul li {
          width: calc(100% / 3); } }
      @media (max-width: 1023px) and (max-width: 767px) {
        .menu-open #primary-menu > ul li {
          width: 50%; } }
      @media (max-width: 1023px) and (max-width: 479px) {
        .menu-open #primary-menu > ul li {
          width: 100%; } }
  @media (max-width: 1023px) {
          .menu-open #primary-menu > ul li:after {
            display: none; }
          .menu-open #primary-menu > ul li a {
            height: auto;
            line-height: 1;
            padding: 10px; }
            .menu-open #primary-menu > ul li a:hover {
              background-color: #20233f;
              color: #ffffff; }
              .menu-open #primary-menu > ul li a:hover span {
                text-decoration: underline; } }
  #primary-menu > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;
    display: flex; }
    @media (max-width: 1023px) {
      #primary-menu > ul {
        flex-direction: column;
        align-items: center; } }
    @media (min-width: 1024px) {
      #primary-menu > ul {
        flex-wrap: nowrap; } }
    #primary-menu > ul > li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      flex: 1;
      text-align: center; }
      #primary-menu > ul > li:after {
        content: "";
        background-color: #ffffff;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0; }
      #primary-menu > ul > li:last-child:after {
        display: none; }
      #primary-menu > ul > li a {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 400;
        height: 77px;
        line-height: 77px;
        display: block;
        color: #ffffff;
        background-color: #20233f;
        text-transform: uppercase;
        vertical-align: middle;
        padding: 0 10px;
        -moz-transition: all, 0.3s;
        -o-transition: all, 0.3s;
        -webkit-transition: all, 0.3s;
        transition: all, 0.3s; }
        @media (max-width: 1279px) {
          #primary-menu > ul > li a {
            text-transform: none; } }
        #primary-menu > ul > li a span {
          font-size: 14px;
          line-height: 18px;
          display: inline-block;
          vertical-align: middle; }
        #primary-menu > ul > li a:hover {
          background-color: #ffffff;
          color: #20233f; }
      #primary-menu > ul > li .sub-menu {
        display: none; }

#footer {
  padding: 0;
  margin: 0; }
  #footer--inner {
    border-top: 2px solid #ffffff;
    background-color: #20233f;
    padding: 50px 0;
    overflow: hidden;
    font-size: 0; }
    @media (max-width: 1279px) {
      #footer--inner .wrap {
        margin: 0;
        text-align: center; } }
    #footer--inner .bloc {
      display: inline-block;
      vertical-align: top;
      padding: 20px;
      text-align: left;
      width: 100%; }
      @media (min-width: 1024px) {
        #footer--inner .bloc.bloc-logo {
          max-width: 395px; }
        #footer--inner .bloc.bloc-links {
          max-width: calc(100% - (395px + 270px)); }
        #footer--inner .bloc.bloc-network {
          max-width: 270px; } }
      @media (min-width: 1279px) {
        #footer--inner .bloc {
          padding: 10px 60px; }
          #footer--inner .bloc.bloc-logo {
            max-width: 475px; }
          #footer--inner .bloc.bloc-links {
            max-width: calc(100% - (475px + 360px)); }
          #footer--inner .bloc.bloc-network {
            max-width: 360px; } }
    @media (max-width: 1023px) {
      #footer--inner .bloc-logo {
        text-align: center; } }
    #footer--inner .bloc-logo .logo {
      margin: 0 0 30px 0; }
    #footer--inner .bloc-logo .description {
      margin: 0 0 30px 0; }
      #footer--inner .bloc-logo .description p {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff; }
    #footer--inner .bloc-logo .links {
      font-family: "Lato", Tahoma, Arial, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #ffffff; }
      #footer--inner .bloc-logo .links a {
        color: #ffffff; }
        #footer--inner .bloc-logo .links a:hover {
          text-decoration: underline; }
    #footer--inner .bloc-links {
      position: relative; }
      @media (max-width: 1023px) {
        #footer--inner .bloc-links {
          text-align: center; }
          #footer--inner .bloc-links:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #a3d2eb; }
          #footer--inner .bloc-links:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #a3d2eb; } }
      @media (min-width: 1024px) {
        #footer--inner .bloc-links:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: #a3d2eb; }
        #footer--inner .bloc-links:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: #a3d2eb; } }
      #footer--inner .bloc-links ul {
        padding: 0;
        margin: 0;
        list-style: none; }
        #footer--inner .bloc-links ul li a {
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          padding: 5px 0;
          display: block;
          text-transform: uppercase;
          color: #a3d2eb;
          -moz-transition: all, 0.3s;
          -o-transition: all, 0.3s;
          -webkit-transition: all, 0.3s;
          transition: all, 0.3s; }
          #footer--inner .bloc-links ul li a:hover {
            color: #ffffff; }
    #footer--inner .bloc-network ul {
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center; }
      #footer--inner .bloc-network ul li {
        margin: 0 30px 0 0;
        display: inline-block; }
        #footer--inner .bloc-network ul li:last-child {
          margin-right: 0; }
        @media (min-width: 1024px) {
          #footer--inner .bloc-network ul li {
            text-align: left;
            display: block;
            margin: 0 0 30px 0; } }
        #footer--inner .bloc-network ul li a {
          display: block; }
          #footer--inner .bloc-network ul li a:hover .inner .picto {
            color: #20233f; }
        #footer--inner .bloc-network ul li .inner {
          font-size: 0;
          white-space: nowrap; }
          #footer--inner .bloc-network ul li .inner .picto {
            margin-right: 17px;
            -moz-transition: all, 0.3s;
            -o-transition: all, 0.3s;
            -webkit-transition: all, 0.3s;
            transition: all, 0.3s; }
          #footer--inner .bloc-network ul li .inner .txt {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 70px); }
            #footer--inner .bloc-network ul li .inner .txt .txt-1 {
              font-family: "Lato", Tahoma, Arial, sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 22px;
              text-transform: uppercase;
              color: #ffffff; }
            #footer--inner .bloc-network ul li .inner .txt .txt-2 {
              font-family: "Lato", Tahoma, Arial, sans-serif;
              font-weight: 900;
              font-size: 18px;
              line-height: 22px;
              text-transform: uppercase;
              color: #a3d2eb; }
    @media (max-width: 767px) {
      #footer--inner .bloc-network {
        text-align: center; }
        #footer--inner .bloc-network > ul {
          display: inline-block;
          vertical-align: top;
          text-align: left; }
          #footer--inner .bloc-network > ul > li {
            display: block;
            margin: 0 0 30px 0; } }
    #footer--inner .picto {
      background-color: #a3d2eb; }

#search_filters .facet .facet-label,
#search_filters_brands .facet .facet-label,
#search_filters_suppliers .facet .facet-label {
  margin-top: .4375rem;
  display: flex;
  align-items: flex-start;
  text-align: left; }
  #search_filters .facet .facet-label a,
  #search_filters_brands .facet .facet-label a,
  #search_filters_suppliers .facet .facet-label a {
    margin-left: 5px;
    margin-top: 0; }

#reassurance {
  background-color: #a3d2eb;
  padding: 20px 0 40px 0;
  margin: 90px 0 0 0; }
  #reassurance .wrap {
    font-size: 0;
    text-align: center; }
  #reassurance .item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 100%;
    max-width: 340px; }
    #reassurance .item a {
      display: inline-block;
      vertical-align: middle;
      padding: 20px;
      width: 100%;
      max-width: 320px; }
      #reassurance .item a:hover .action {
        text-decoration: underline; }
      #reassurance .item a:hover .img {
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1); }
  #reassurance .inner {
    display: inline-block;
    vertical-align: top; }
    #reassurance .inner .img {
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      -moz-transition: all, 0.3s;
      -o-transition: all, 0.3s;
      -webkit-transition: all, 0.3s;
      transition: all, 0.3s; }
    #reassurance .inner .title {
      position: relative;
      padding: 0 0 20px 0;
      margin: 0 0 15px 0; }
      #reassurance .inner .title:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 24px;
        margin-left: -12px;
        height: 3px;
        background-color: #20233f; }
      #reassurance .inner .title p {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 900;
        font-size: 22px;
        line-height: 26px;
        color: #20233f;
        text-transform: uppercase; }
    #reassurance .inner .description {
      margin: 0 0 20px 0;
      padding: 0 20px; }
      #reassurance .inner .description p {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #20233f; }
    #reassurance .inner .action {
      font-family: "Lato", Tahoma, Arial, sans-serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #20233f; }

#home-slider {
  margin: 20px 0;
  background-color: #ffffff; }
  #home-slider:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 1023px) {
    #home-slider {
      margin: 0; } }
  #home-slider img {
    max-width: 100%;
    height: auto; }
  #home-slider .col {
    float: left; }
    #home-slider .col.col-1 {
      width: 74.375%; }
    #home-slider .col.col-2 {
      width: 25.625%;
      border-top: 1px solid #20233f;
      border-bottom: 1px solid #20233f; }
    @media (max-width: 1023px) {
      #home-slider .col.col-1 {
        width: 100%;
        float: none; }
      #home-slider .col.col-2 {
        width: 100%;
        margin: auto;
        float: none;
        border: none; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    #home-slider .col.col-2 {
      max-width: 350px; } }
  #home-slider .slides {
    white-space: nowrap;
    overflow: hidden; }
    @media (max-width: 1023px) {
      #home-slider .slides {
        background-color: #20233f; } }
    #home-slider .slides .item {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: center center; }
      #home-slider .slides .item .inner {
        position: relative;
        overflow: hidden;
        height: 354px; }
        @media (max-width: 1023px) {
          #home-slider .slides .item .inner {
            height: auto; } }
        #home-slider .slides .item .inner .img img {
          visibility: hidden; }
        #home-slider .slides .item .inner .txt {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 15px 67px;
          text-align: right;
          border-radius: 0 500px 500px 0;
          background-color: #20233f;
          background-image: url("../../img/home-slider-picto.png");
          background-repeat: no-repeat;
          background-position: right center;
          width: 100%;
          max-width: 650px;
          -moz-transition: all, 0.3s;
          -o-transition: all, 0.3s;
          -webkit-transition: all, 0.3s;
          transition: all, 0.3s; }
          @media (max-width: 1023px) {
            #home-slider .slides .item .inner .txt {
              position: relative;
              bottom: 0;
              left: 0;
              border-radius: 0;
              text-align: center;
              background-image: none;
              padding: 10px;
              max-width: 100%; } }
          #home-slider .slides .item .inner .txt .txt-1 {
            display: inline-block;
            vertical-align: middle;
            font-family: "Lato", Tahoma, Arial, sans-serif;
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            text-transform: uppercase;
            color: #ffffff;
            padding: 0 0 0 15px;
            -moz-transition: all, 0.3s;
            -o-transition: all, 0.3s;
            -webkit-transition: all, 0.3s;
            transition: all, 0.3s; }
            @media (max-width: 1023px) {
              #home-slider .slides .item .inner .txt .txt-1 {
                padding: 0 15px; } }
      #home-slider .slides .item a:hover .txt .txt-1 {
        padding-right: 15px; }
    #home-slider .slides ul.slick-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 18px;
      border-radius: 0 500px 500px 0;
      margin: 0;
      list-style: none;
      white-space: nowrap;
      font-size: 0; }
      @media (max-width: 1023px) {
        #home-slider .slides ul.slick-dots {
          position: relative;
          bottom: 0;
          left: 0;
          border-radius: 0;
          text-align: center;
          background-color: #20233f;
          padding: 10px; } }
      #home-slider .slides ul.slick-dots li {
        display: inline-block;
        vertical-align: middle;
        padding: 5px; }
        #home-slider .slides ul.slick-dots li button {
          display: inline-block;
          vertical-align: middle;
          padding: 0;
          margin: 0;
          width: 14px;
          height: 14px;
          border: 2px solid #20233f;
          background-color: #ffffff;
          -moz-transition-property: all;
          -o-transition-property: all;
          -webkit-transition-property: all;
          transition-property: all;
          -moz-transition-duration: 0.3s;
          -o-transition-duration: 0.3s;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          border-radius: 500px; }
        #home-slider .slides ul.slick-dots li.slick-active button {
          -moz-transform: scale(1.3);
          -o-transform: scale(1.3);
          -ms-transform: scale(1.3);
          -webkit-transform: scale(1.3);
          transform: scale(1.3);
          border: 1px solid #ffffff;
          background-color: #20233f; }
  #home-slider .products {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0; }
    #home-slider .products .item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      -moz-transition: all, 0.3s;
      -o-transition: all, 0.3s;
      -webkit-transition: all, 0.3s;
      transition: all, 0.3s; }
      @media (min-width: 768px) and (max-width: 1023px) {
        #home-slider .products .item.slick-current {
          background-color: #a3d2eb; }
          #home-slider .products .item.slick-current a:hover .action span {
            border: 1px solid #20233f; }
          #home-slider .products .item.slick-current .action {
            background-color: #a3d2eb; }
            #home-slider .products .item.slick-current .action span {
              border: 1px solid #20233f; } }
      #home-slider .products .item a {
        display: block;
        text-decoration: none; }
        #home-slider .products .item a:hover {
          moz-transform-origin: center center;
          -o-transform-origin: center center;
          -ms-transform-origin: center center;
          -webkit-transform-origin: center center;
          transform-origin: center center; }
          #home-slider .products .item a:hover .action span {
            background-color: #a3d2eb;
            border: 1px solid #a3d2eb; }
      #home-slider .products .item .inner {
        position: relative;
        padding: 20px 20px 80px 20px;
        height: 352px;
        text-align: center; }
        @media (max-width: 1023px) {
          #home-slider .products .item .inner {
            height: 380px; } }
      #home-slider .products .item .img {
        margin-bottom: 10px; }
      #home-slider .products .item .product-img {
        margin-bottom: 10px;
        height: 180px; }
        #home-slider .products .item .product-img img {
          margin: auto;
          height: 100%;
          width: 100%;
          object-fit: contain; }
      #home-slider .products .item .description p {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #20233f;
        text-align: center; }
      @media (max-width: 1023px) {
        #home-slider .products .item .product-img {
          margin-bottom: 10px; } }
      #home-slider .products .item .product-name {
        margin-bottom: 5px; }
        #home-slider .products .item .product-name p {
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 700;
          position: relative;
          overflow: hidden;
          font-size: 18px;
          line-height: 22px;
          height: 44px;
          padding: 0;
          text-transform: uppercase;
          color: #20233f;
          margin: 0; }
      #home-slider .products .item .product-description {
        margin-bottom: 5px; }
        #home-slider .products .item .product-description p {
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 400;
          position: relative;
          overflow: hidden;
          font-size: 15px;
          line-height: 20px;
          height: 60px;
          padding: 0;
          color: #20233f; }
      #home-slider .products .item .product-price {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        color: #20233f; }
      #home-slider .products .item .action {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 20px 20px;
        text-align: center;
        background-color: #ffffff;
        -moz-transition: all, 0.3s;
        -o-transition: all, 0.3s;
        -webkit-transition: all, 0.3s;
        transition: all, 0.3s; }
        #home-slider .products .item .action span {
          display: block;
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #ffffff;
          text-transform: uppercase;
          background-color: #20233f;
          border: 1px solid #20233f;
          border-radius: 15px;
          padding: 10px;
          -moz-transition: all, 0.3s;
          -o-transition: all, 0.3s;
          -webkit-transition: all, 0.3s;
          transition: all, 0.3s; }

#push-products {
  position: relative;
  margin: 40px 0; }
  #push-products .title {
    position: relative;
    text-align: center;
    margin: 0 0 40px 0; }
    #push-products .title > span {
      position: relative;
      font-family: "Lato", Tahoma, Arial, sans-serif;
      font-weight: 900;
      font-size: 26px;
      line-height: 32px;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: middle;
      padding: 0 20px;
      background-color: #ffffff;
      color: #20233f; }
    #push-products .title:before {
      content: "";
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #20233f; }
  #push-products .arrows-container {
    position: relative;
    float: right;
    z-index: 10;
    background-color: #ffffff; }
    #push-products .arrows-container button {
      padding: 0 10px;
      margin: 0;
      border: none;
      background-color: #ffffff;
      color: #20233f;
      -moz-transition: all, 0.3s;
      -o-transition: all, 0.3s;
      -webkit-transition: all, 0.3s;
      transition: all, 0.3s;
      font-size: 30px;
      line-height: 35px; }
      #push-products .arrows-container button:hover {
        color: #a3d2eb; }
      #push-products .arrows-container button.slick-disabled {
        opacity: 0.1;
        color: #20233f; }
  #push-products .carousel-products {
    font-size: 0; }
    #push-products .carousel-products .item {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      padding: 10px; }
      #push-products .carousel-products .item a {
        display: block;
        text-decoration: none;
        -moz-transition: all, 0.3s;
        -o-transition: all, 0.3s;
        -webkit-transition: all, 0.3s;
        transition: all, 0.3s; }
        #push-products .carousel-products .item a:hover {
          -webkit-box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
          moz-transform-origin: center center;
          -o-transform-origin: center center;
          -ms-transform-origin: center center;
          -webkit-transform-origin: center center;
          transform-origin: center center; }
          #push-products .carousel-products .item a:hover .action span {
            background-color: #a3d2eb; }
      #push-products .carousel-products .item .inner {
        padding: 10px; }
      #push-products .carousel-products .item .product-img img {
        margin: auto; }
      #push-products .carousel-products .item .product-name {
        margin-bottom: 5px; }
        #push-products .carousel-products .item .product-name p {
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 700;
          position: relative;
          overflow: hidden;
          font-size: 18px;
          line-height: 22px;
          height: 44px;
          padding: 0;
          text-transform: uppercase;
          color: #20233f;
          margin: 0; }
      #push-products .carousel-products .item .product-description {
        margin-bottom: 5px; }
        #push-products .carousel-products .item .product-description p {
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 400;
          position: relative;
          overflow: hidden;
          font-size: 15px;
          line-height: 20px;
          height: 60px;
          padding: 0;
          color: #20233f; }
      #push-products .carousel-products .item .product-price {
        font-family: "Lato", Tahoma, Arial, sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        color: #20233f; }
      #push-products .carousel-products .item .action {
        text-align: center;
        margin-top: 10px; }
        #push-products .carousel-products .item .action span {
          display: block;
          font-family: "Lato", Tahoma, Arial, sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #ffffff;
          text-transform: uppercase;
          background-color: #20233f;
          border-radius: 15px;
          padding: 10px;
          -moz-transition: all, 0.3s;
          -o-transition: all, 0.3s;
          -webkit-transition: all, 0.3s;
          transition: all, 0.3s; }

#push-customer-service {
  background-color: #a3d2eb; }
  #push-customer-service .content {
    background-image: url("../../img/push-customer-service-2.png");
    background-repeat: no-repeat;
    background-position: right center; }
    @media (max-width: 479px) {
      #push-customer-service .content {
        background-image: none;
        text-align: center; } }
  #push-customer-service .txt {
    padding: 30px 30px 30px 100px;
    background-image: url("../../img/push-customer-service-1.png");
    background-repeat: no-repeat;
    background-position: left center; }
    @media (max-width: 479px) {
      #push-customer-service .txt {
        padding: 100px 30px 30px 30px;
        background-position: top center; } }
  #push-customer-service .txt-1 {
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #20233f; }
  #push-customer-service .nw {
    display: inline-block; }
  #push-customer-service .txt-2 {
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: #ffffff; }

#partners {
  margin: 90px 0; }
  #partners .wrap {
    font-size: 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  #partners .item {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 700px) {
      #partners .item {
        width: 25%; } }
    @media (max-width: 699px) {
      #partners .item {
        width: 50%; } }
    @media (min-width: 1024px) {
      #partners .item {
        border: 1px solid #20233f;
        border-top: none;
        border-left: none; }
        #partners .item:nth-child(4n+4), #partners .item:last-child {
          border-right: none; }
        #partners .item:nth-child(n+5) {
          border-bottom: none; } }
  #partners .img {
    -webkit-filter: grayscale(100);
    -moz-filter: grayscale(100);
    -ms-filter: grayscale(100);
    -o-filter: grayscale(100);
    filter: grayscale(100);
    -moz-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s;
    padding: 10px 40px; }
    @media (max-width: 1220px) {
      #partners .img {
        padding: 10px 15px; } }
    #partners .img:hover {
      -webkit-filter: grayscale(0);
      -moz-filter: grayscale(0);
      -ms-filter: grayscale(0);
      -o-filter: grayscale(0);
      filter: grayscale(0); }
  #partners .img {
    -webkit-filter: grayscale(100);
    -moz-filter: grayscale(100);
    -ms-filter: grayscale(100);
    -o-filter: grayscale(100);
    filter: grayscale(100);
    -moz-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s;
    padding: 10px 0; }
    #partners .img:hover {
      -webkit-filter: grayscale(0);
      -moz-filter: grayscale(0);
      -ms-filter: grayscale(0);
      -o-filter: grayscale(0);
      filter: grayscale(0); }
    #partners .img img {
      object-fit: scale-down;
      object-position: center center;
      max-width: 100%;
      padding: 0 10px; }

.home-title {
  position: relative;
  text-align: center;
  margin: 0 0 40px 0; }
  .home-title > span {
    position: relative;
    font-family: "Lato", Tahoma, Arial, sans-serif;
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    background-color: #ffffff;
    color: #20233f; }
  .home-title:before {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #20233f; }

#categories {
  font-size: 0;
  text-align: left; }
  @media (max-width: 600px) {
    #categories {
      text-align: center; } }
  #categories .item {
    width: 25%;
    display: inline-block;
    vertical-align: top; }
    @media (max-width: 1279px) {
      #categories .item {
        width: calc(100% / 3); } }
    @media (max-width: 1023px) {
      #categories .item {
        width: calc(100% / 2); } }
    @media (max-width: 600px) {
      #categories .item {
        width: 98%; } }
    #categories .item.item-logo a {
      background-color: #a3d2eb; }
    #categories .item a {
      display: block;
      height: 130px;
      line-height: 130px;
      border: 1px solid #ffffff;
      background-color: #20233f;
      -moz-transition: all, 0.3s;
      -o-transition: all, 0.3s;
      -webkit-transition: all, 0.3s;
      transition: all, 0.3s; }
      #categories .item a:hover {
        background-color: #a3d2eb; }
        #categories .item a:hover .img svg {
          fill: #20233f; }
        #categories .item a:hover .name {
          color: #20233f; }
    #categories .item .inner {
      padding: 0 30px;
      white-space: nowrap;
      text-align: center; }
    #categories .item .img {
      display: inline-block;
      vertical-align: middle;
      width: 80px;
      height: 80px;
      line-height: 1;
      margin-right: 10px; }
      #categories .item .img svg {
        fill: #a3d2eb;
        -moz-transition: all, 0.3s;
        -o-transition: all, 0.3s;
        -webkit-transition: all, 0.3s;
        transition: all, 0.3s; }
    #categories .item .name {
      display: inline-block;
      vertical-align: middle;
      font-family: "Lato", Tahoma, Arial, sans-serif;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #ffffff;
      -moz-transition: all, 0.3s;
      -o-transition: all, 0.3s;
      -webkit-transition: all, 0.3s;
      transition: all, 0.3s;
      white-space: normal;
      width: calc(100% - 90px);
      text-align: left; }

#product-modal figure {
  position: relative; }

#product-modal .modal-content .modal-body .product-images {
  width: 9.25rem; }

#product-modal .modal-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee; }
  #product-modal .modal-close svg {
    width: 20px; }

.bootstrap-touchspin {
  padding-right: 20px; }
  .bootstrap-touchspin .input-group-btn-vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 20px;
    position: absolute;
    right: 0;
    justify-content: space-between; }
    .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
      border-top: 0 !important; }
    .bootstrap-touchspin .input-group-btn-vertical > .btn {
      position: relative;
      right: auto;
      padding: 0;
      width: 100%;
      height: 50%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-left: 0;
      font-size: 0; }
      .bootstrap-touchspin .input-group-btn-vertical > .btn:after {
        font-size: 15px; }

#my-account .page-footer a {
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #a3d2eb;
  margin-bottom: 1.875rem;
  height: 100%;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #20233F;
  padding: 1rem;
  transition: .3s all; }
  #my-account .page-footer a:hover {
    color: #ffffff; }

.product-miniature .product-flag-wishlist {
  display: none !important; }

.block-category {
  min-height: auto !important; }

#v-cookielaw {
  overflow: hidden;
  background: #fff;
  color: #444;
  font-size: 13px; }
  #v-cookielaw .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    @media (max-width: 1023px) {
      #v-cookielaw .container {
        flex-direction: column; } }
  #v-cookielaw.v-bar {
    padding: 4px 0; }
    #v-cookielaw.v-bar.v-top {
      position: relative;
      border-bottom: 1px solid #000; }
    #v-cookielaw.v-bar .v-actions {
      display: flex;
      align-items: center;
      text-align: right;
      margin: 10px 0; }
  #v-cookielaw.v-yellow-alert {
    background: #fffbcd;
    border-color: #e2cd7b !important; }
  #v-cookielaw .v-message {
    padding: 0 10px;
    text-align: left; }
  #v-cookielaw .v-button {
    white-space: nowrap;
    margin-left: 5px;
    color: #222;
    display: inline-block;
    background-color: #ececec;
    background-image: linear-gradient(#f4f4f4, #ececec);
    border: 1px solid #d4d4d4;
    padding: 0 10px;
    text-decoration: none;
    text-shadow: 1px 1px 0 #fff;
    border-radius: 2px; }
    #v-cookielaw .v-button.v-accept::before {
      content: "\2714 "; }

.product-quantity #quantity_wanted,
.bootstrap-touchspin input.form-control,
.bootstrap-touchspin input.input-group {
  width: 4rem !important; }

#order-details {
  padding: 15px; }

.add-to-cart.add-to-cart-disable {
  font-weight: 700;
  margin: 0; }
