$sans-serif: Tahoma, Arial, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: Arial, Tahoma, sans-serif;;
$times: $serif;
@mixin arial {
    font-family: $arial;
}
@mixin times {
    font-family: $times;
}
$lato: 'Lato', $sans-serif;
@mixin lato {
    font-family: $lato;
    font-weight: 400;
}
@mixin lato-bold {
    font-family: $lato;
    font-weight: 700;
}
@mixin lato-black {
    font-family: $lato;
    font-weight: 900;
}