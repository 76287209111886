@import "partials/mixins";
@import "partials/variables";
@import "partials/typography";
@import "plugins/slick";

*,
*:after,
*:before {
  @include box-sizing(border-box);
}

.visuallyhidden {
  @include visuallyhidden;
}

.clearfix {
  @include clearfix;
}

html {
  //font-size:62.5%;
}

body {

}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
#wrapper {
  background: $white;
}

.container {
  width: 1280px;
  max-width: 100%;
  margin: auto;

  .container {
    padding: 0;
  }
}

.header {
  &--row-1 {
    background-color: $blue-light;
    padding: 2px 0;
    border-bottom: 2px solid $white;

    &--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mq(null, 1023px) {
        flex-direction: column;
      }
    }
  }

  &--logo {
    position: relative;
    padding: 0 10px;
    margin: 5px 0;
    text-align: center;

    img {
      @include mq(1024px, 1279px) {
        max-width: 220px;
        height: auto;
      }
    }
  }

  &--search-contact-user-cart {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @include mq(null, 1023px) {
      display: none;
      .menu-account-open & {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  &--search-contact,
  &--user-cart {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include mq(null, 580px) {
      flex-wrap: wrap;
    }
  }

  &--search-contact {
    .search-form,
    .customer-service {
      margin: 5px 0;
    }

    .search-form {
      position: relative;
      padding: 0 10px;
      width: 335px;
      max-width: 100%;
      @include mq(null, 359px) {
        width: 100%;
      }

      input[type="text"] {
        @include lato;
        @include font-size-lh(18, 22);
        color: $blue-dark;
        height: 40px;
        padding: 5px 60px 5px 20px;
        margin: 6px 0;
        border: 1px solid $white;
        border-radius: 20px;
        width: 100%;
        max-width: 100%;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        margin: 0 0 0 -53px;
        background: none;
        border: none;
      }
    }

    .customer-service {
      position: relative;
      padding: 0 10px;
      white-space: nowrap;

      .txt {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }

      .txt-1 {
        @include lato-bold;
        @include font-size-lh(16, 22);
        color: $white;
        text-transform: uppercase;
      }

      .txt-2 {
        @include lato-black;
        @include font-size-lh(18, 22);
        color: $blue-dark;
      }
    }
  }

  &--user-cart {
    .account,
    .cart {
      position: relative;
      text-align: center;
      white-space: normal;
      margin: 5px 0;
      padding: 0 10px;

      .title {
        @include lato-black;
        @include font-size-lh(12, 16);
        color: $blue-dark;
        text-transform: uppercase;
      }
    }

    .account {
      &--logged {
        padding-right: 15px;
      }

      &--logout {
        position: absolute;
        top: 0;
        right: 0;

        .picto {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          border-radius: 100%;
          background-color: $blue-dark;
          text-align: center;
          color: $white;
          vertical-align: middle;
        }
      }
    }

    .cart {
      .cart-number {
        @include position(absolute, null, -10px, 0, null);
        display: inline-block;
        vertical-align: middle;
        width: 21px;
        height: 21px;
        background-color: $white;
        text-align: center;
        border-radius: 100%;
        @include lato-black;
        @include font-size-lh(12, 21);
        color: $blue-dark;
      }
    }
  }

  &--row-2 {
    background-color: $blue-dark;
    .container {
      display:flex;
      padding: 0;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mq(1024px, null) {
        flex-wrap: nowrap;
      }
    }
    .break {
      flex-basis: 100%;
      height: 0;
      @include mq(1024px, null) {
        display:none;
      }
    }
  }
}

.picto {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 53px;
  line-height: 53px;
  font-size: 22px;
  border-radius: 100%;
  background-color: $blue-dark;
  text-align: center;
  color: $white;
  vertical-align: middle;
}

#menu-search {
  display: none;
  @include mq(null, 1023px) {
    display: block;
    flex:1;
  }

  .search-form {
    padding:0;
    width: auto !important;
    label, input {
      font-size:12px;
    }
    .picto {
      font-size: 16px;
      border-radius: 0;
      width: 32px;
    }
  }
  .customer-service {
    display: none;
  }
}
#menu-trigger {
  display: none;
  @include mq(null, 1023px) {
    display: block;
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    background: none;
    color: $white;
    @include transition(all, 0.3s);

    .fa-times {
      display: none;
    }

    .fa-bars {
      display: inline-block;
    }

    &:hover {
      @include transform(scale(1.1));
    }

    .menu-open & {
      .fa-times {
        display: inline-block;
      }

      .fa-bars {
        display: none;
      }
    }
  }
}

#menu-account-trigger {
  display: none;
  @include mq(null, 1023px) {
    display: block;
    float: right;
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    background: none;
    color: $white;
    @include transition(all, 0.3s);

    .fa-user-times {
      display: none;
    }

    .fa-user-plus {
      display: inline-block;
    }

    &:hover {
      @include transform(scale(1.1));
    }

    .menu-account-open & {
      .fa-user-times {
        display: inline-block;
      }

      .fa-user-plus {
        display: none;
      }
    }
  }
}

#primary-menu {
  border-bottom: 1px solid $blue-dark;
  flex:1;
  @include mq(null, 1023px) {
    display: none;
    .menu-open & {
      display: block;

      > ul {
        li {
          width: calc(100% / 3);
          @include mq(null, 767px) {
            width: 50%;
          }
          @include mq(null, 479px) {
            width: 100%;
          }

          &:after {
            display: none;
          }

          a {
            height: auto;
            line-height: 1;
            padding: 10px;

            &:hover {
              background-color: $blue-dark;
              color: $white;

              span {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;
    display:flex;
    @include mq(null, 1023px) {
      flex-direction: column;
      align-items: center;
    }
    @include mq(1024px, null) {
      flex-wrap: nowrap;
    }


    > li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      flex:1;
      //width: calc(100% / 8);
      text-align: center;

      &:after {
        content: "";
        background-color: $white;
        width: 1px;
        height: 100%;
        @include position(absolute, 0, 0, null, null);
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      a {
        @include lato;
        height: 77px;
        line-height: 77px;
        display: block;
        color: $white;
        background-color: $blue-dark;
        text-transform: uppercase;
        vertical-align: middle;
        padding: 0 10px;
        @include transition(all, 0.3s);
        @include mq(null, 1279px) {
          text-transform: none;
        }

        span {
          @include font-size-lh(14, 18);
          display: inline-block;
          vertical-align: middle;
        }

        &:hover {
          background-color: $white;
          color: $blue-dark;
        }
      }

      .sub-menu {
        display: none;
      }
    }
  }
}

#footer {
  padding: 0;
  margin: 0;

  &--inner {
    border-top: 2px solid $white;
    background-color: $blue-dark;
    padding: 50px 0;
    overflow: hidden;
    font-size: 0;

    .wrap {
      //margin: 0 -60px;
      @include mq(null, 1279px) {
        margin: 0;
        text-align: center;
      }
    }

    .bloc {
      display: inline-block;
      vertical-align: top;
      //width: calc(100% / 3);
      padding: 20px;
      text-align: left;
      width: 100%;
      @include mq(1024px, null) {
        &.bloc-logo {
          max-width: 395px;
        }
        &.bloc-links {
          //max-width: 300px;
          max-width: calc(100% - (395px + 270px));
        }
        &.bloc-network {
          max-width: 270px;
        }
      }
      @include mq(1279px, null) {
        padding: 10px 60px;
        &.bloc-logo {
          max-width: 475px;
        }
        &.bloc-links {
          max-width: calc(100% - (475px + 360px));
        }
        &.bloc-network {
          max-width: 360px;
        }
      }
    }

    .bloc-logo {
      @include mq(null, 1023px) {
        text-align: center;
      }

      .logo {
        margin: 0 0 30px 0;
      }

      .description {
        margin: 0 0 30px 0;

        p {
          @include lato;
          @include font-size-lh(15, 20);
          color: $white;
        }
      }

      .links {
        @include lato;
        @include font-size-lh(14, 18);
        text-transform: uppercase;
        color: $white;

        a {
          color: $white;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .bloc-links {
      position: relative;
      @include mq(null, 1023px) {
        text-align: center;
        &:before {
          content: "";
          @include position(absolute, 0, null, null, 0);
          width: 100%;
          height: 1px;
          background-color: $blue-light;
        }
        &:after {
          content: "";
          @include position(absolute, null, null, 0, 0);
          width: 100%;
          height: 1px;
          background-color: $blue-light;
        }
      }
      @include mq(1024px, null) {
        &:before {
          content: "";
          @include position(absolute, 0, null, null, 0);
          width: 1px;
          height: 100%;
          background-color: $blue-light;
        }
        &:after {
          content: "";
          @include position(absolute, 0, 0, null, null);
          width: 1px;
          height: 100%;
          background-color: $blue-light;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          a {
            @include lato-bold;
            @include font-size-lh(16, 18);
            padding: 5px 0;
            display: block;
            text-transform: uppercase;
            color: $blue-light;
            @include transition(all, 0.3s);

            &:hover {
              color: $white;
            }
          }
        }
      }
    }

    .bloc-network {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
          margin: 0 30px 0 0;
          display: inline-block;

          &:last-child {
            margin-right: 0;
          }

          @include mq(1024px, null) {
            text-align: left;
            display: block;
            margin: 0 0 30px 0;
          }

          a {
            display: block;

            &:hover {
              .inner {
                .picto {
                  color: $blue-dark;
                }
              }
            }
          }

          .inner {
            font-size: 0;
            white-space: nowrap;

            .picto {
              margin-right: 17px;
              @include transition(all, 0.3s);
            }

            .txt {
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 70px);

              .txt-1 {
                @include lato-bold;
                @include font-size-lh(16, 22);
                text-transform: uppercase;
                color: $white;
              }

              .txt-2 {
                @include lato-black;
                @include font-size-lh(18, 22);
                text-transform: uppercase;
                color: $blue-light;
              }
            }
          }
        }
      }

      @include mq(null, 767px) {
        text-align: center;
        > ul {
          display: inline-block;
          vertical-align: top;
          text-align: left;

          > li {
            display: block;
            margin: 0 0 30px 0;
          }
        }
      }
    }

    .picto {
      background-color: $blue-light;
    }
  }
}


#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  .facet {
    .facet-label {
      margin-top: .4375rem;
      display: flex;
      align-items: flex-start;
      text-align: left;

      a {
        margin-left: 5px;
        margin-top: 0;
      }
    }
  }
}


#reassurance {
  background-color: $blue-light;
  padding: 20px 0 40px 0;
  margin: 90px 0 0 0;

  .wrap {
    font-size: 0;
    text-align: center;
  }

  .item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 100%;
    max-width: 340px;

    a {
      display: inline-block;
      vertical-align: middle;
      padding: 20px;
      width: 100%;
      max-width: 320px;

      &:hover {
        .action {
          text-decoration: underline;
        }

        .img {
          @include transform(scale(1));
        }
      }
    }
  }

  .inner {
    display: inline-block;
    vertical-align: top;

    .img {
      @include transform(scale(0.9));
      @include transition(all, 0.3s);
    }

    .title {
      position: relative;
      padding: 0 0 20px 0;
      margin: 0 0 15px 0;

      &:before {
        content: "";
        @include position(absolute, null, null, 0, 50%);
        width: 24px;
        margin-left: -12px;
        height: 3px;
        background-color: $blue-dark;
      }

      p {
        @include lato-black;
        @include font-size-lh(22, 26);
        color: $blue-dark;
        text-transform: uppercase;
      }
    }

    .description {
      margin: 0 0 20px 0;
      padding: 0 20px;

      p {
        @include lato;
        @include font-size-lh(15, 20);
        color: $blue-dark;
      }
    }

    .action {
      @include lato-black;
      @include font-size-lh(14, 18);
      text-transform: uppercase;
      color: $blue-dark;
    }
  }
}

#home-slider {
  margin: 20px 0;
  background-color: $white;
  @include clearfix;
  @include mq(null, 1023px) {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .col {
    float: left;

    &.col-1 {
      //width: 952px;
      width: 74.375%;
    }

    &.col-2 {
      //width: 328px;
      width: 25.625%;
      border-top: 1px solid $blue-dark;
      border-bottom: 1px solid $blue-dark;
    }

    @include mq(null, 1023px) {
      &.col-1 {
        width: 100%;
        float: none;
      }
      &.col-2 {
        width: 100%;
        margin: auto;
        float: none;
        border: none;
        @include mq(null, 767px) {
          max-width: 350px;
        }
      }
    }
  }

  .slides {
    white-space: nowrap;
    overflow: hidden;
    @include mq(null, 1023px) {
      background-color: $blue-dark;
    }

    .item {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      @include background-size(cover);
      background-position: center center;

      &.slick-active {
      }

      .inner {
        position: relative;
        overflow: hidden;
        height: 354px;
        @include mq(null, 1023px) {
          height: auto;
        }

        .img {
          img {
            visibility: hidden;
          }
        }

        .txt {
          @include position(absolute, null, null, 0, 0);
          padding: 15px 67px;
          text-align: right;
          border-radius: 0 500px 500px 0;
          background-color: $blue-dark;
          background-image: url('../../img/home-slider-picto.png');
          background-repeat: no-repeat;
          background-position: right center;
          width: 100%;
          max-width: 650px;
          @include transition(all, 0.3s);
          @include mq(null, 1023px) {
            @include position(relative, null, null, 0, 0);
            border-radius: 0;
            text-align: center;
            background-image: none;
            padding: 10px;
            max-width: 100%;
          }

          .txt-1 {
            display: inline-block;
            vertical-align: middle;
            @include lato-bold;
            @include font-size-lh(22, 30);
            text-transform: uppercase;
            color: $white;
            padding: 0 0 0 15px;
            @include transition(all, 0.3s);
            @include mq(null, 1023px) {
              padding: 0 15px;
            }
          }
        }
      }

      a {
        &:hover {
          .txt {
            .txt-1 {
              padding-right: 15px;
            }
          }
        }
      }
    }

    ul.slick-dots {
      @include position(absolute, null, null, 0, 0);
      padding: 18px;
      border-radius: 0 500px 500px 0;
      margin: 0;
      list-style: none;
      white-space: nowrap;
      font-size: 0;
      //background-color: $blue-dark;
      @include mq(null, 1023px) {
        @include position(relative, null, null, 0, 0);
        border-radius: 0;
        text-align: center;
        background-color: $blue-dark;
        padding: 10px;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        padding: 5px;

        button {
          display: inline-block;
          vertical-align: middle;
          padding: 0;
          margin: 0;
          width: 14px;
          height: 14px;
          border: 2px solid $blue-dark;
          background-color: $white;
          @include transition-property(all);
          @include transition-duration(0.3s);
          border-radius: 500px;
        }

        &.slick-active {
          button {
            @include transform(scale(1.3));
            border: 1px solid $white;
            background-color: $blue-dark;
          }
        }
      }
    }
  }

  .products {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;

    .item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      @include transition(all, 0.3s);
      @include mq(768px, 1023px) {
        &.slick-current {
          background-color: $blue-light;

          a {
            &:hover {
              .action {
                span {
                  border: 1px solid $blue-dark;
                }
              }
            }
          }

          .action {
            background-color: $blue-light;

            span {
              border: 1px solid $blue-dark;
            }
          }
        }
      }

      a {
        display: block;
        text-decoration: none;

        &:hover {
          @include transform-origin(center center);

          .action {
            span {
              background-color: $blue-light;
              border: 1px solid $blue-light;
            }
          }
        }
      }

      .inner {
        position: relative;
        padding: 20px 20px 80px 20px;
        height: 352px;
        text-align:center;
        @include mq(null, 1023px) {
          height: 380px;
        }
      }

      .img {
        margin-bottom:10px;
      }

      .product-img {
        margin-bottom: 10px;
        height:180px;

        img {
          margin: auto;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .description {
        p {
          @include lato;
          @include font-size-lh(14, 18);
          color: $blue-dark;
          text-align: center;
        }
      }

      .product-img {
        @include mq(null, 1023px) {
          margin-bottom: 10px;
        }
      }

      .product-name {
        margin-bottom: 5px;

        p {
          @include lato-bold;
          @include dotdotdot(18, 22, 2);
          text-transform: uppercase;
          color: $blue-dark;
          margin: 0;
        }
      }

      .product-description {
        margin-bottom: 5px;

        p {
          @include lato;
          @include dotdotdot(15, 20, 3);
          color: $blue-dark;
        }
      }

      .product-price {
        @include lato-bold;
        @include font-size-lh(15, 20);
        text-align: right;
        color: $blue-dark;
      }

      .action {
        @include position(absolute, null, null, 0, 0);
        width: 100%;
        padding: 10px 20px 20px 20px;
        text-align: center;
        background-color: $white;
        @include transition(all, 0.3s);

        span {
          display: block;
          @include lato;
          @include font-size-lh(14, 15);
          color: $white;
          text-transform: uppercase;
          background-color: $blue-dark;
          border: 1px solid $blue-dark;
          border-radius: 15px;
          padding: 10px;
          @include transition(all, 0.3s);
        }
      }
    }
  }
}

#push-products {
  position: relative;
  margin: 40px 0;

  .title {
    position: relative;
    text-align: center;
    margin: 0 0 40px 0;

    > span {
      position: relative;
      @include lato-black;
      @include font-size-lh(26, 32);
      text-transform: uppercase;
      display: inline-block;
      vertical-align: middle;
      padding: 0 20px;
      background-color: $white;
      color: $blue-dark;
    }

    &:before {
      content: "";
      @include position(absolute, 50%, null, 0, 0);
      width: 100%;
      height: 1px;
      background-color: $blue-dark;
    }
  }

  .arrows-container {
    position: relative;
    float: right;
    z-index: 10;
    background-color: $white;

    button {
      padding: 0 10px;
      margin: 0;
      border: none;
      background-color: $white;
      color: $blue-dark;
      @include transition(all, 0.3s);
      @include font-size-lh(30, 35);

      &:hover {
        color: $blue-light;
      }

      &.slick-disabled {
        opacity: 0.1;
        color: $blue-dark;
      }
    }
  }

  .carousel-products {
    font-size: 0;

    .item {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      padding: 10px;

      a {
        display: block;
        text-decoration: none;
        @include transition(all, 0.3s);

        &:hover {
          @include box-shadow(0 0 10px -5px rgba(0, 0, 0, 0.3));
          @include transform-origin(center center);

          .action {
            span {
              background-color: $blue-light;
            }
          }
        }
      }

      .inner {
        padding: 10px;
      }

      .product-img {
        img {
          margin: auto;
        }
      }

      .product-name {
        margin-bottom: 5px;

        p {
          @include lato-bold;
          @include dotdotdot(18, 22, 2);
          text-transform: uppercase;
          color: $blue-dark;
          margin: 0;
        }
      }

      .product-description {
        margin-bottom: 5px;

        p {
          @include lato;
          @include dotdotdot(15, 20, 3);
          color: $blue-dark;
        }
      }

      .product-price {
        @include lato-bold;
        @include font-size-lh(15, 20);
        text-align: right;
        color: $blue-dark;
      }

      .action {
        text-align: center;
        margin-top: 10px;

        span {
          display: block;
          @include lato;
          @include font-size-lh(14, 15);
          color: $white;
          text-transform: uppercase;
          background-color: $blue-dark;
          border-radius: 15px;
          padding: 10px;
          @include transition(all, 0.3s);
        }
      }
    }
  }
}

#push-customer-service {
  background-color: $blue-light;

  .content {
    background-image: url('../../img/push-customer-service-2.png');
    background-repeat: no-repeat;
    background-position: right center;
    @include mq(null, 479px) {
      background-image: none;
      text-align: center;
    }
  }

  .txt {
    padding: 30px 30px 30px 100px;
    background-image: url('../../img/push-customer-service-1.png');
    background-repeat: no-repeat;
    background-position: left center;
    @include mq(null, 479px) {
      padding: 100px 30px 30px 30px;
      background-position: top center;
    }
  }

  .txt-1 {
    @include lato-black;
    @include font-size-lh(26, 32);
    text-transform: uppercase;
    color: $blue-dark;
  }
  .nw {
    display:inline-block;
  }

  .txt-2 {
    @include lato-bold;
    @include font-size-lh(22, 32);
    color: $white;
  }
}

#partners {
  margin: 90px 0;
  .wrap {
    font-size: 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(700px, null){
      width: 25%;
    }
    @include mq(null,699px){
      width: 50%;
    }
    @include mq(1024px,null){
      border: 1px solid #20233f;
      border-top: none;
      border-left: none;
      &:nth-child(4n+4),
      &:last-child {
        border-right: none;
      }
      &:nth-child(n+5){
        border-bottom: none;
      }
    }
  }
  .img {
    @include filter(grayscale, 100);
    @include transition(all,0.3s);
    padding: 10px 40px;
    @include mq(null,1220px){
      padding: 10px 15px;
    }
    &:hover {
      @include filter(grayscale, 0);
    }
  }


  .img {
    @include filter(grayscale, 100);
    @include transition(all, 0.3s);
    padding: 10px 0;

    &:hover {
      @include filter(grayscale, 0);
    }

    img {
      object-fit: scale-down;
      object-position: center center;
      max-width: 100%;
      padding: 0 10px;
    }
  }
}

.home-title {
  position: relative;
  text-align: center;
  margin: 0 0 40px 0;

  > span {
    position: relative;
    @include lato-black;
    @include font-size-lh(26, 32);
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    background-color: $white;
    color: $blue-dark;
  }

  &:before {
    content: "";
    @include position(absolute, 50%, null, 0, 0);
    width: 100%;
    height: 1px;
    background-color: $blue-dark;
  }
}

#categories {
  font-size: 0;
  text-align: left;
  @include mq(null, 600px) {
    text-align: center;
  }

  .item {
    width: 25%;
    //max-width: 320px;
    display: inline-block;
    vertical-align: top;
    @include mq(null, 1279px) {
      width: calc(100% / 3);
    }
    @include mq(null, 1023px) {
      width: calc(100% / 2);
    }
    @include mq(null, 600px) {
      width: 98%;
    }

    &.item-logo {
      a {
        background-color: $blue-light;
      }
    }

    a {
      display: block;
      height: 130px;
      line-height: 130px;
      border: 1px solid $white;
      background-color: $blue-dark;
      @include transition(all, 0.3s);

      &:hover {
        background-color: $blue-light;

        .img {
          svg {
            fill: $blue-dark;
          }
        }

        .name {
          color: $blue-dark;
        }
      }
    }

    .inner {
      padding: 0 30px;
      white-space: nowrap;
      text-align: center;
    }

    .img {
      display: inline-block;
      vertical-align: middle;
      width: 80px;
      height: 80px;
      line-height: 1;
      margin-right: 10px;

      svg {
        fill: $blue-light;
        @include transition(all, 0.3s);
      }
    }

    .name {
      display: inline-block;
      vertical-align: middle;
      @include lato-black;
      @include font-size-lh(18, 22);
      text-transform: uppercase;
      color: $white;
      @include transition(all, 0.3s);
      white-space: normal;
      width: calc(100% - 90px);
      text-align: left;
    }
  }
}

#product-modal {
  figure {
    position: relative;
  }
  .modal-content {
    .modal-body {
      .product-images {
        width: 9.25rem;
      }
    }
  }
  .modal-close {
      cursor:pointer;
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background:#eee;
    svg {
    width:20px;
    }
  }

}

.bootstrap-touchspin {
  padding-right: 20px;
  .input-group-btn-vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 20px;
    position: absolute;
    right: 0;
    justify-content: space-between;
    .bootstrap-touchspin-down {
      border-top: 0 !important;
    }
    > .btn {
      position: relative;
      right:auto;
      padding: 0;
      width: 100%;
      height: 50%;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,.25);
      border-left: 0;
      font-size: 0;
      &:after {
        font-size: 15px;
      }
    }
  }
}


#my-account .page-footer a {
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #a3d2eb;
  margin-bottom: 1.875rem;
  height: 100%;
  box-shadow: 2px 2px 8px 0 rgba(0,0,0,.2);
  background: #20233F;
  padding: 1rem;
  transition:.3s all;
  &:hover {
    color:#ffffff;
  }
}

.product-miniature .product-flag-wishlist {
  display: none !important;
}

.block-category {
  min-height:auto !important;
}


#v-cookielaw {
  overflow: hidden;
  background: #fff;
  color: #444;
  font-size: 13px;
  .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @include mq(null, 1023px) {
      flex-direction:column;
    }
  }
  &.v-bar {
    padding: 4px 0;
    &.v-top {
      position: relative;
      border-bottom: 1px solid #000;
    }
    .v-actions {
      display: flex;
      align-items: center;
      text-align: right;
      margin: 10px 0;
    }
  }
  &.v-yellow-alert {
    background: #fffbcd;
    border-color: #e2cd7b !important;
  }
  .v-message {
    padding: 0 10px;
    text-align: left;
  }
  .v-button {
    white-space: nowrap;
    margin-left: 5px;
    color: #222;
    display: inline-block;
    background-color: #ececec;
    background-image: linear-gradient(#f4f4f4, #ececec);
    border: 1px solid #d4d4d4;
    padding: 0 10px;
    text-decoration: none;
    text-shadow: 1px 1px 0 #fff;
    border-radius: 2px;

    &.v-accept::before {
      content: "\2714 ";
    }
  }
}

.product-quantity #quantity_wanted,
.bootstrap-touchspin input.form-control,
.bootstrap-touchspin input.input-group {
  width:4rem !important;
}

#order-details {
  padding:15px;
}

.add-to-cart {
  &.add-to-cart-disable {
    font-weight: 700;
    margin: 0;
  }
}